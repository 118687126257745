import React from "react";

type Props = {};

const WhoIsBBT = (props: Props) => {
  return (
    <section className="flex flex-col gap-4 mt-8">
      <img
        src="./logo-bbt.png"
        alt="bbt logo"
        className="w-full max-h-40 object-contain object-center"
      />
      <div>
        <h2 className="text-2xl font-bold text-white">BBT Kimdir?</h2>
        <p className="md:text-lg text-slate-200">
          Game Jam, oyun geliştirme süreçlerini hızlandırarak katılımcıların 48
          saat içinde sıfırdan oyun geliştirmelerini amaçlayan etkinliklerdir.
          Katılımcılar, genellikle takımlar halinde çalışır ve kısıtlı zaman
          içinde yaratıcılıklarını kullanarak bir oyun tasarlar ve geliştirir.
        </p>
      </div>
    </section>
  );
};

export default WhoIsBBT;
