import { NavLink, useLocation } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { IoHeart } from "react-icons/io5";
import { FaPenNib } from "react-icons/fa6";

type Props = {};
const activeClassnames: string = "opacity-100";
const deactiveClassnames: string = "opacity-50";
const classNames = "flex items-center gap-2 px-4 py-0 text-white text-lg";

const Navbar = (props: Props) => {
  const location = useLocation().pathname;

  return (
    <nav className="flex items-center justify-center h-12">
      <NavLink
        to="/"
        className={({ isActive }) =>
          `${isActive ? activeClassnames : deactiveClassnames} ${classNames}`
        }
      >
        <AiFillHome />
        <span className={`${location === "/" ? "block" : "hidden md:block"}`}>
          Ana Sayfa
        </span>
      </NavLink>
      <NavLink
        to="/sponsorlar"
        className={({ isActive }) =>
          `${isActive ? activeClassnames : deactiveClassnames} ${classNames}`
        }
      >
        <IoHeart />
        <span
          className={`${
            location === "/sponsorlar" ? "block" : "hidden md:block"
          }`}
        >
          Sponsorlar
        </span>
      </NavLink>
      <NavLink
        to="/kayit"
        className={({ isActive }) =>
          `${isActive ? activeClassnames : deactiveClassnames} ${classNames}`
        }
      >
        <FaPenNib />
        <span
          className={`${location === "/kayit" ? "block" : "hidden md:block"}`}
        >
          Kayıt Formu
        </span>
      </NavLink>
    </nav>
  );
};

export default Navbar;
