import React from "react";

type Props = {};

const WhatIsGamejam = (props: Props) => {
  return (
    <section className="flex flex-col gap-2 mt-8">
      <h2 className="text-2xl font-bold text-white">Game Jam Nedir?</h2>
      <p className="md:text-lg text-slate-200">
        Game Jam, oyun geliştirme süreçlerini hızlandırarak katılımcıların 48
        saat içinde sıfırdan oyun geliştirmelerini amaçlayan etkinliklerdir.
        Katılımcılar, genellikle takımlar halinde çalışır ve kısıtlı zaman
        içinde yaratıcılıklarını kullanarak bir oyun tasarlar ve geliştirir.
      </p>
      <h2 className="text-2xl font-bold text-white mt-4">
        Kimler Katılabilir?
      </h2>
      <p className="md:text-lg text-slate-200">
        Game Jam etkinliğimiz, oyun geliştirme dünyasına adım atmak isteyen
        herkes için harika bir fırsat! İster profesyonel bir oyun tasarımcısı
        olun, ister programlama ile yeni tanışmış bir amatör ya da sadece
        yaratıcı fikirlerinizle katkıda bulunmak isteyen biri olun, herkes bu
        heyecan verici etkinliklere katılabilir. Kendi ekibinizi kurabilir veya
        mevcut ekiplerle birleşebilirsiniz. Bu etkinlikler, yeni insanlarla
        tanışmak, harika projelere imza atmak ve eğlenceli bir şekilde
        yeteneklerinizi test etmek için mükemmel bir yerdir. Katılmak için kayıt
        olmayı unutmayın!
      </p>
    </section>
  );
};

export default WhatIsGamejam;
