import React, { useState } from "react";

interface FaqItem {
  question: string;
  answer: string;
}

const FaqAccordion = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const faqItems: FaqItem[] = [
    {
      question: "BBT Game Jam 2024’e kimler katılmalı?",
      answer:
        "Oyun sektörüne ilgi duyan, oyun yapmayı denemek isteyen veya kendini bu alanda geliştirmek isteyen, sektörden yeni insanlar tanımak isteyen ve tüm bunları yaparken eğlenmek isteyen herkes bu etkinliğe katılmalı!",
    },
    {
      question: "Etkinliğe nasıl katılırım?",
      answer:
        "Etkinliğe katılmak için başvuru formu üzerinden başvuru yapmanız yeterlidir.",
    },
    {
      question: "Katılım ücreti var mı?",
      answer: "Etkinlik tamamen ücretsizdir.",
    },
    {
      question: "Etkinlik nerede olacak?",
      answer:
        "Etkinlik Kütahya Dumlupınar Üniversitesinin Kampüsünde bulunan Bedesten binasında gerçekleştirilmektedir.",
    },
    {
      question: "Etkinliğe sadece yarışmacılar mı katılabilir?",
      answer:
        "Hayır. BBT Game Jam etkinlikleri sadece oyun geliştirmekten çok bir oyun festiveli gibidir içeride istediğiniz gibi oyunlar oynayabilir, sosyalleşebilir veya eğlenceli vakit geçirebilirsiniz",
    },
    {
      question: "Etkinliğe tek başıma katılabilir miyim?",
      answer:
        "Evet katılabilirsiniz. İster tek başınıza yarışabilir ister alandaki diğer takım veya kişilerle anlaşıp yeni bir ekip oluşturabilirsiniz.",
    },
    {
      question: "Takımlarda kişi sayısı sınırı var mı?",
      answer: "",
    },
    {
      question: "Birden fazla oyunda veya ekipte yer alabilir miyim?",
      answer: "",
    },
    {
      question: "Hazır asset kullanımı serbest mi?",
      answer: "",
    },
  ];

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-full mx-auto mt-8 grid grid-cols-1 md:grid-cols-2 gap-4 mb-20">
      {faqItems.map((item, index) => (
        <div
          key={index}
          className="border-b border-gray-700 rounded-md overflow-hidden"
        >
          <button
            className={`w-full text-left py-2 px-4 text-white ${
              activeIndex === index ? "bg-blue-500" : "bg-gray-800"
            } flex justify-between items-center`}
            onClick={() => toggleAccordion(index)}
          >
            <span>{item.question}</span>
            <span>{activeIndex === index ? "▼" : "►"}</span>
          </button>
          {activeIndex === index && (
            <div className="px-4 py-2 bg-gray-700 text-white">
              {item.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FaqAccordion;
