import React from "react";

type Sponsor = {
  name: string;
  image: string;
  link?: string;
};

type Props = {
  title: string;
  sponsors: Sponsor[];
};

const SponsorItem = (props: Sponsor) => {
  return (
    <div
      className="flex flex-col gap-2"
      onClick={() => (props.link ? window.open(props.link, "_blank") : "")}
    >
      <img
        src={props.image}
        alt={props.name}
        className="w-48 h-48 object-contain object-center"
      />
      <p className="text-slate-200 text-center font-semibold">{props.name}</p>
    </div>
  );
};

const SponsorContainer = (props: Props) => {
  return (
    <section className="mb-8">
      <h1 className="text-3xl font-bold text-center text-white">
        {props.title}
      </h1>
      <div className="flex flex-row items-center justify-center gap-4 flex-wrap">
        {props.sponsors.map((sponsor) => (
          <SponsorItem key={sponsor.name} {...sponsor} />
        ))}
      </div>
    </section>
  );
};

export default SponsorContainer;
