import {
  FaInstagram,
  FaYoutube,
  FaDiscord,
  FaLinkedinIn,
} from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { IoLogoWhatsapp, IoMdMail } from "react-icons/io";
import { FaEarthAfrica } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";

type Props = {};

const Contact = (props: Props) => {
  return (
    <section className="relative bg-slate-600/30 p-2 sm:p-4 rounded-xl w-full mt-8 shadow-md">
      <h2 className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-0 text-white py-2 px-4 rounded-full bg-gradient-to-b from-blue-700 to-blue-800 text-nowrap">
        İletişim
      </h2>
      <div className="flex items-center justify-center mt-2">
        <p className="text-slate-200 py-2 text-center">
          İletişim için aşağıdaki linkleri kullanabilir veya sosyal medya
          hesaplarımızı takip edebilirsiniz
        </p>
      </div>
      <div
        className="grid gap-2 mt-2"
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
        }}
      >
        <a
          href="https://www.instagram.com/dpubbt/"
          target="_blank"
          className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 shadow-md hover:bg-slate-900/30 transition-colors text-white text-2xl"
        >
          <FaInstagram />
        </a>
        <a
          href="https://x.com/i/flow/login?redirect_after_login=%2Fdpubbt43"
          target="_blank"
          className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 shadow-md hover:bg-slate-900/30 transition-colors text-white text-2xl"
        >
          <BsTwitterX />
        </a>
        <a
          href="https://www.youtube.com/@dpubbt"
          target="_blank"
          className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 shadow-md hover:bg-slate-900/30 transition-colors text-white text-2xl"
        >
          <FaYoutube />
        </a>
        <a
          href="https://discord.gg/bTYpa6VcJE"
          target="_blank"
          className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 shadow-md hover:bg-slate-900/30 transition-colors text-white text-2xl"
        >
          <FaDiscord />
        </a>
        <a
          href="https://linkedin.com/company/dpubbt"
          target="_blank"
          className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 shadow-md hover:bg-slate-900/30 transition-colors text-white text-2xl"
        >
          <FaLinkedinIn />
        </a>
        <a
          href="https://chat.whatsapp.com/HCD5qjz01XyIbcNRMY8cFP"
          target="_blank"
          className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 shadow-md hover:bg-slate-900/30 transition-colors text-white text-2xl"
        >
          <IoLogoWhatsapp />
        </a>
        <a
          href="mailto:dpubilgisayarvebilisim@gmail.com"
          target="_blank"
          className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 shadow-md hover:bg-slate-900/30 transition-colors text-white text-2xl"
        >
          <IoMdMail />
        </a>
        <a
          href="https://www.dpubbt.com/"
          target="_blank"
          className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 shadow-md hover:bg-slate-900/30 transition-colors text-white text-2xl"
        >
          <FaEarthAfrica />
        </a>
        <a
          href="tel:905468016713"
          target="_blank"
          className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 shadow-md hover:bg-slate-900/30 transition-colors text-white text-2xl"
        >
          <FaPhone />
        </a>
      </div>
    </section>
  );
};

export default Contact;
