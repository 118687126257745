import { MdQuestionMark } from "react-icons/md";

type User = {
  image: string;
  name: string;
  surname: string;
  position: string;
};

type Props = {
  user?: User;
};

const Jury = (props: Props) => {
  if (props.user)
    return (
      <div className="w-44 flex flex-col items-center justify-center border-2 border-slate-600 rounded-xl p-5 hover:bg-gradient-to-b hover:from-slate-900/60 hover:to-slate-950/60 transition-colors">
        <img
          className="w-24 h-24 rounded-full object-cover object-center"
          src={props.user.image}
          alt="Mildred Mitchell"
        />
        <p className="text-base font-bold text-white mt-4 text-center">
          {`${props.user.name} ${props.user.surname}`}
        </p>
        <p className="text-sm text-slate-300">{props.user.position}</p>
      </div>
    );
  return (
    <div className="w-44 flex flex-col items-center justify-center border-2 border-slate-600 hover:bg-gradient-to-b hover:from-slate-900/60 hover:to-slate-950/60 transition-colors rounded-xl p-5">
      <div className="flex items-center justify-center text-white text-4xl w-24 h-24 rounded-full bg-gradient-to-b from-blue-700/50 to-blue-800/50">
        <MdQuestionMark />
      </div>
      <p className="text-base font-bold text-white/50 mt-4">Yakında..</p>
    </div>
  );
};

export default Jury;
