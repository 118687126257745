import { useState, useMemo } from "react";
import Modal from "react-modal";

type Props = {};

const gamejam2022: string[] = [
  "/2022Images/IMG_4418.webp",
  "/2022Images/IMG_4686.webp",
  "/2022Images/IMG_4719.webp",
  "/2022Images/IMG_4902.webp",
  "/2022Images/IMG_5057.webp",
  "/2022Images/IMG_5482.webp",
  "/2022Images/IMG_5839.webp",
];
const gamejam2023: string[] = [
  "/2023Images/DF4B9EEC-1936-42E5-8C42-6F3EE8732903.webp",
  "/2023Images/DSC01081.webp",
  "/2023Images/DSC01083.webp",
  "/2023Images/IMG_5839.webp",
  "/2023Images/IMG_7230.webp",
];

Modal.setAppElement("#root"); // Accessibility için gerekli

const PreviousGamejam = (props: Props) => {
  const [year, setYear] = useState(2022);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  // Memoize the image list to avoid recalculating on every render
  const images = useMemo(() => {
    return year === 2022 ? gamejam2022 : gamejam2023;
  }, [year]);

  const openModal = (image: string) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const renderImage = (image: string, index: number) => (
    <div key={index} className="relative" onClick={() => openModal(image)}>
      <img
        src={image}
        alt="image"
        className="w-full h-56 object-fill object-center blur-sm opacity-25 absolute top-0 left-0 -z-10"
      />
      <img
        src={image}
        alt="image"
        className="w-full h-56 object-contain object-center cursor-pointer"
      />
    </div>
  );

  return (
    <section className="relative bg-slate-600/30 rounded-xl w-full mt-8 shadow-md overflow-hidden">
      <div>
        <button
          onClick={() => setYear(2022)}
          className={`${
            year == 2022 ? "bg-white" : "bg-white/50"
          } px-6 py-4 rounded-t-xl`}
        >
          2022
        </button>
        <button
          onClick={() => setYear(2023)}
          className={`${
            year == 2023 ? "bg-white" : "bg-white/50"
          } px-6 py-4 rounded-t-xl`}
        >
          2023
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {images.map(renderImage)}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center"
      >
        {selectedImage && (
          <div className="relative">
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-white text-2xl"
            >
              ×
            </button>
            <img
              src={selectedImage}
              alt="selected"
              className="max-w-full max-h-full object-contain"
            />
          </div>
        )}
      </Modal>
    </section>
  );
};

export default PreviousGamejam;
