import React from "react";

type Props = {};

const Register = (props: Props) => {
  return (
    <section
      className="flex flex-col items-center justify-center"
      style={{
        minHeight: "calc(100vh - 48px)",
      }}
    >
      <p className="text-white font-semibold text-2xl text-center">
        Kayıtlarımız yakında açılacaktır. Duyuru için takipte kalın.
      </p>
    </section>
  );
};

export default Register;
