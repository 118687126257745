import { Outlet } from "react-router-dom";

function App() {
  return (
    <main className="App container mx-auto px-2 ">
      <Outlet />
    </main>
  );
}

export default App;
