import { Link } from "react-router-dom";
import { IoGameController } from "react-icons/io5";

type Props = {};

const EventTimer = (props: Props) => {
  return (
    <section className="relative bg-slate-600/30 p-2 sm:p-4 rounded-xl w-full mt-8 shadow-md">
      <h2 className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-0 text-white py-2 px-4 rounded-full bg-gradient-to-b from-blue-700 to-blue-800 text-nowrap">
        Etkinliğin Başlamasına
      </h2>
      <div className="flex items-center justify-center gap-2">
        <div className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 sm:p-4 md:p-0 md:w-20 md:h-20 mt-5 shadow-md hover:bg-slate-900/30 transition-colors">
          <p className="text-white font-semibold text-lg sm:text-2xl">05</p>
          <span className="text-slate-400 font-semibold text-sm">Gün</span>
        </div>
        <div className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 sm:p-4 md:p-0 md:w-20 md:h-20 mt-5 shadow-md hover:bg-slate-900/30 transition-colors">
          <p className="text-white font-semibold text-lg sm:text-2xl">14</p>
          <span className="text-slate-400 font-semibold text-sm">Saat</span>
        </div>
        <div className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 sm:p-4 md:p-0 md:w-20 md:h-20 mt-5 shadow-md hover:bg-slate-900/30 transition-colors">
          <p className="text-white font-semibold text-lg sm:text-2xl">56</p>
          <span className="text-slate-400 font-semibold text-sm">Dakika</span>
        </div>
        <div className="border border-slate-500 rounded-xl flex flex-col items-center justify-center p-3 sm:p-4 md:p-0 md:w-20 md:h-20 mt-5 shadow-md hover:bg-slate-900/30 transition-colors">
          <p className="text-white font-semibold text-lg sm:text-2xl">07</p>
          <span className="text-slate-400 font-semibold text-sm">Saniye</span>
        </div>
      </div>
      <div className="flex items-center justify-center mt-4">
        <Link
          to="/kayit"
          className="flex items-center gap-2 text-xl font-semibold text-white py-2 px-4 rounded-full hover:bg-blue-950 transition-colors text-nowrap"
        >
          <IoGameController />
          Etkinliğe Katıl
        </Link>
      </div>
    </section>
  );
};

export default EventTimer;
