import Header from "../components/Mainpage/Header";
import EventTimer from "../components/Mainpage/EventTimer";
import SponsorContainer from "../components/Sponsors/SponsorContainer";

type Props = {};

const Sponsors = (props: Props) => {
  return (
    <div
      className="flex flex-col gap-8"
      style={{
        minHeight: "calc(100vh - 48px)",
      }}
    >
      <Header />
      <EventTimer />
      <SponsorContainer
        title="X Sponsorlarımız"
        sponsors={[
          {
            name: "Madrooster",
            image: "./sponsors/madrooster-renkli.png",
            link: "https://madrooster.com",
          },
        ]}
      />
      <SponsorContainer
        title="Y Sponsorlarımız"
        sponsors={[
          {
            name: "SwishSwoosh",
            image: "./sponsors/SwishSwoosh Logo_Dark BG.png",
          },
        ]}
      />
    </div>
  );
};

export default Sponsors;
