type Props = {};

const Header = (props: Props) => {
  return (
    <header className="flex flex-col items-center">
      <img src="./logo.svg" alt="logo" className="w-44 h-44 md:w-60 md:h-60" />
      <p className="text-slate-200 text-xl text-center">
        Hayal gücünü konuştur,{" "}
        <span className="bg-gradient-to-r from-orange-400 via-purple-400 to-red-400 inline-block text-transparent bg-clip-text font-bold text-2xl">
          11-13 Ekim
        </span>
        'de Kütahya Dumlupınar Üniversitesinin Bedesten binasındaki 48 saatlik
        oyun serüvenine katıl!
      </p>
    </header>
  );
};

export default Header;
