import { Link } from "react-router-dom";
import { IoGameController } from "react-icons/io5";

type Props = {};

const EventTimer = (props: Props) => {
  return (
    <section className="p-2">
      <div className="flex items-center justify-center gap-6 sm:gap-8 md:gap-12">
        <div className="flex flex-col items-center justify-center">
          <p className="text-slate-300 font-semibold text-base sm:text-2xl">
            6.000₺
          </p>
          <span className="text-slate-300 font-semibold text-sm">İkinciye</span>
        </div>
        <div className="flex flex-col items-center justify-center scale-125">
          <p className="text-yellow-500 font-semibold text-base sm:text-2xl">
            8.000₺
          </p>
          <span className="text-yellow-500 font-semibold text-sm">
            Birinciye
          </span>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-orange-400 font-semibold text-base sm:text-2xl">
            4.000₺
          </p>
          <span className="text-orange-400 font-semibold text-sm">
            Üçüncüye
          </span>
        </div>
      </div>
    </section>
  );
};

export default EventTimer;
